import React from "react"
// import  feature_img1  from "../../images/feature_img_1.png";
import styles from "./styles.module.css";
import FeatureGraphic from "../featureGraphic";
import { StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import AshinShah from "../../gatsby-images/ashin-shah";
import AtikShah from "../../gatsby-images/atik-shah";
import KiranShah from "../../gatsby-images/kiran-shah";
import ManishJain from "../../gatsby-images/manish-jain";
import PallavBhatt from "../../gatsby-images/pallav-bhatt";

const TeamImages = {
  AshinShah: <AshinShah/>,
  AtikShah: <AtikShah/>,
  KiranShah: <KiranShah/>,
  ManishJain: <ManishJain/>,
  PallavBhatt: <PallavBhatt/>
}

const LeaderItem = React.forwardRef(({imageOnRight=true,content},ref) => (
    <StaticQuery query={graphql`
      query {
        curve: file(relativePath: { eq: "curve-1.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        dots: file(relativePath: { eq: "F-Pattern.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
      }
    `}
    render={data => {
        const curve = data.curve.childImageSharp.fluid
        const dots = data.dots.childImageSharp.fluid
        return (
            <BackgroundImage 
                className={[styles.feature_box,imageOnRight ? styles.dots : styles.curve].join(" ")}
                fluid={imageOnRight ? dots : curve}
            >
                <div className={["container",styles.featureListContainer].join(" ")} ref={ref}>
                    <div className="row">
                        <div className={["col-lg-3", "mb-4", "mb-md-0", "d-flex", 'justify-content-md-center', imageOnRight ? "order-lg-2" : "", "pb-md-4", "pb-lg-0"].join(" ")}>
                            <div className={[styles.feature_img_box,"feature_img_box","col-md-6","col-lg-12","pl-0","pr-0"].join(' ')}>
                                {
                                  TeamImages[content.image]
                                }
                            </div>
                        </div>
                        <div className={["col-lg-9"].join(" ")}>
                            <div className={styles.feature_detail}>
                              <div className='d-flex flex-column align-items-stretch'>
                                <h2 className={[styles.featureTitle,'text-lg-left', 'text-center'].join(' ')}>{content.name}</h2>
                                <div className={[styles.featureSubtitle,'text-lg-left', 'text-center'].join(' ')}>{content.designation}</div>
                              </div>

                              <span>{content.bio}</span>

                                {/* <ul>
                                    {
                                        feature.bullets.map((bullet,index) => {
                                          return (
                                            <li key={index}>{bullet}</li>
                                          )
                                        })
                                    }
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        )
    }}
    />
))

export default LeaderItem
