import React from "react"

import FeatureAllInvestmentsGraphic from "../../gatsby-images/feature-all-investments-graphic";
import FeatureMultiplePortfoliosGraphic from "../../gatsby-images/feature-multiple-portfolios-graphic";
import FeatureLivePricesGraphic from "../../gatsby-images/feature-live-prices-graphic";
import FeatureAdvisorDashboardGraphic from "../../gatsby-images/feature-advisor-dashboard-graphic";
import FeatureCapgainXirrGraphic from "../../gatsby-images/feature-capgain-xirr-graphic";
import FeatureFnoGraphic from "../../gatsby-images/feature-fno-graphic";
import FeatureAccountsGraphic from "../../gatsby-images/feature-accounts-graphic";
import FeatureImportGraphic from "../../gatsby-images/feature-import-graphic";
import FeatureAdvisorSharingGraphic from "../../gatsby-images/feature-advisor-sharing-graphic";
import FeatureReportGraphic from "../../gatsby-images/feature-reports-graphic";
import FeatureCapgainReportmodalGraphic from "../../gatsby-images/feature-capgain-reportmodal-graphic";

const FeatureGraphics = {
    'all-investments': <FeatureAllInvestmentsGraphic/>,
    'multiple-portfolios': <FeatureMultiplePortfoliosGraphic/>,
    'live-prices': <FeatureLivePricesGraphic/>,
    'advisor-dashboard': <FeatureAdvisorDashboardGraphic/>,
    'capgain-xirr': <FeatureCapgainXirrGraphic/>,
    'fno': <FeatureFnoGraphic/>,
    'accounts': <FeatureAccountsGraphic/>,
    'import': <FeatureImportGraphic/>,
    'advisor-sharing': <FeatureAdvisorSharingGraphic/>,
    'reports': <FeatureReportGraphic/>,
    'capgain-reportmodal': <FeatureCapgainReportmodalGraphic/>
}

const FeatureGraphic = ({type}) => {
    return (
        <>
        {
            FeatureGraphics[type] || FeatureGraphics['all-investments']
        }
        </>
    )
}

export default FeatureGraphic;