import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureAccountsGraphic = () => (
  <StaticQuery
    query={graphql`
      query {
        single: file(relativePath: { eq: "graphics/feature-accounts/components/Layer 2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Img fluid={data.single.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt="Automate your investment-related accounting"/>
      )
    }}
  />
)
export default FeatureAccountsGraphic
