import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureAdvisorSharingGraphic = () => (
  <StaticQuery
    query={graphql`
      query {
        single: file(relativePath: { eq: "graphics/feature-advisor-sharing/single/graphic.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Img fluid={data.single.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt="Advisors can enable clients to view their portfolios on web & mobile"/>
      )
    }}
  />
)
export default FeatureAdvisorSharingGraphic
