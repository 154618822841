import React from "react"
import styles from "./styles.module.css";

import LeaderItem from "../leaderItem";

class OurLeadership extends React.Component {
  constructor(props) {
    super(props);

    this.leaderRefs = this.props.leadershipContent.list.reduce((acc, value) => {
      acc[value.order] = React.createRef();
      return acc;
    }, {});  

  }

  componentDidMount() {
    console.log(this.props.location,'location')
    if (this.props.location && this.props.location.state && this.props.location.state.activeLeader) {
        const activeRef = this.leaderRefs[this.props.location.state.activeLeader];
        console.log(activeRef,'location')
        if (activeRef.current) {
            setTimeout(()=>{
                activeRef.current.scrollIntoView({ offsetTop: -120});
                window.scrollBy(0, -160)
            },300);
        }
    }
  }

  render() {
    const {leadershipContent} = this.props;
    return (
      <div>
          <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
            <div className={['align-self-end', 'w-100'].join(' ')}>
                <div className="align-self-stretch">
                    <div className={["container",styles.featureListContainer].join(" ")}>
                        <div className={["row", "justify-content-md-center"].join(" ")}>
                            <div className={[styles.mpr_main_title ,'col-12', 'mb-5', 'pb-5'].join(' ')}>
                                <h2 className="text-center text-lg-left">{'Our Founders'}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div>
            {
              leadershipContent.list.map((x,index) => {
                return (
                  <LeaderItem content={x} imageOnRight={index%2===0} key={index} ref={this.leaderRefs[x.order]}/>
                )
              })
            }
          </div>
        </div>
    )
  }
}

export default OurLeadership
