import React from "react"

import FLayout from "../components/flayout/index.js";
import OurLeadership from "../components/ourLeadership";

const Page = ({data,location}) => (
  <FLayout seoFields={seoFields} darkHeader={true}>
    <OurLeadership data={data} leadershipContent={data.allContentJson.edges[0].node.aboutContent.team} location={location}/>
  </FLayout>
)

const seoFields = {
  description: "Learn about the people and their roles at MProfit.",
  // keywords,
  title: "Leadership",
  mprofitBefore: true
}

export const query = graphql`
query leadershipContentQuery{
  allContentJson {
    edges {
      node {
        aboutContent {
          team {
            headline
            list {
              order
              name
              designation
              image
              bio
            }
          }
        }
      }
    }
  }
}
`

export default Page
