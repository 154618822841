import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FeatureLivePricesGraphic = () => (
  <StaticQuery
    query={graphql`
      query {
        single: file(relativePath: { eq: "graphics/feature-live-prices/single/graphic.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Img fluid={data.single.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt="Stay updated with live valuations for your portfolios"/>
      )
    }}
  />
)
export default FeatureLivePricesGraphic
